@import 'src/assets/styles/_variables.scss';

.ag-theme-material, .ag-theme-material .ag-header, .ag-theme-material .ag-header-row {
    font-size: $size-7;
    --ag-row-hover-color: transparent;
}

.ag-root-wrapper-body.ag-layout-normal {
    border: 1px solid $border-color;
}

.ag-header-group-cell {
    border-right: 1px solid $border-color !important;

    &:hover {
        background-color: $ra-primary-blue !important;
        opacity: $hover-opacity;
    }

    .ag-icon {
        color: $ra-text-white;
    }
}

.ag-header-cell, .ag-header-group-cell {
    border-right: 1px solid $border-color !important;
    justify-content: center;
    padding: 0 !important;

    &:hover {
        background-color: $ra-primary-blue !important;
        opacity: $hover-opacity;
    }

    .ag-icon {
        color: $ra-text-white;
    }
}

.ag-theme-material {
    .ag-header {
        .ag-header-cell, .ag-header-group-cell {
            color: $ra-text-white !important;
            border-bottom: none !important;
        }
    }
}

.ag-cell {
    border-right: 1px solid $faded-border-color !important;
    display: flex;
    align-items: center;
    padding: 0 .5em !important;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
}

.ag-header-group-cell-label {
    justify-content: center;
    font-weight: bold;
}

.ag-header-group-cell {
    border-right: 1px solid $border-color !important;
}

.ag-cell-focus {
    border: 1px solid $ra-primary-blue !important;
}

.ag-row {
    border-top-color: $faded-border-color !important;
    border-bottom-color: $faded-border-color !important;
}

.special-event-cell {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    i {
        margin-left: .75em;
    }
}

.ag-cell-inline-editing {
    height: 24px !important;
    border-radius: 0 !important;
}

.ag-input-wrapper {
    height: auto !important;
}

.edited-cell {
    background: #ff9999 !important;
    font-weight: bold;
}

.ag-header-group-cell-label, .ag-header-cell-label {
    justify-content: center;
}

/* Cell warning levels */
.warn-error {
    background-color: rgba($ra-secondary-red, .5) !important;
}

.warn-info {
    background-color: rgba($ra-support-gray, .5) !important;
}

.cell-data {
    color: $black;
}
