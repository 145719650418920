@use '@angular/material' as mat;

@import 'src/assets/styles/_variables.scss';

button {
    margin: 0;
    padding: 0;
    background-color: inherit;
    border: none;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.mat-mdc-button {
        padding: 0.5em;
    }
}

.mat-button-toggle {
    &.mat-button-toggle-checked {
        button {
            background-color: $ra-primary-blue;
        }
    }
}

.button {
    &:focus {
        color: $black;
    }

    &:focus-visible {
        outline: 2px solid $black;
    }

    &.is-link {
        &:focus {
            color: $black;
        }
    }
}

.fill-space {
    /* This fills the remaining space, by using flexbox.
       Every toolbar row uses a flexbox row layout. */
    flex: 1 1 auto;
}

// Side Nav and Top Nav Styling
.topnav {
    height: $default-nav-height !important;
    background-color: $ra-primary-blue !important;
    color: $ra-text-white !important;

    @media (max-width: 599px) {
        height: $mobile-nav-height !important;
        flex-direction: column !important;
    }

    .mat-mdc-button strong {
        color: $ra-text-white !important;
    }
}

i.is-ra-blue {
    color: $ra-primary-blue !important;
}

// bulma css is interfering with the datepicker calender alignment, this corrects it
.mat-calendar-table th {
    text-align: center !important;
}

.sidenav-container {
    height: 95%;
}

.dashboard-card {
    position: absolute;
    margin: 1em;
    height: 92%;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.width-45-pct {
    width: 45%;
}

.width-50-pct {
    width: 50%;
}

.no-background {
    background: none;
}

.cursor-crosshair {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.vertical-align-0 {
    vertical-align: 0 !important;
}

.margin-left-half-em {
    margin-left: .5em !important;
}

.height-100-pct {
    height: 100%;
}

.width-100-pct {
    width: 100%;
}

.width-25-pct {
    width: 25%;
}

.no-padding {
    padding: 0 !important;
}

.error-color {
    color: $danger;
}

.warning-color {
    color: $warning;
}

.success-color {
    color: $success;
}

.no-border-toolbar {
    margin: -1em !important;
}

.grid-button {
    height: 2em;
    width: 2em;
}

.sub-toolbar {
    height: 2.5em !important;
    font-size: 1em;
    width: auto !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.margin-top-75-em {
    margin-top: .75em !important;
}

.padding-75-em {
    padding: .75em !important;
}

.padding-top-75-em {
    padding-top: .75em !important;
}

.padding-quarter-em {
    padding: .25em !important;
}

.margin-right-half-em {
    margin-right: .5em !important;
}

.margin-bottom-1-em {
    margin-bottom: 1em !important;
}

.ra-primary-blue {
    background-color: $ra-primary-blue !important;
    color: $ra-text-white !important;

    &.mat-expansion-panel-header {
        &:hover {
            opacity: $hover-opacity !important;
        }
    }

    .mat-expansion-indicator::after {
        color: $ra-text-white !important;
    }
}

.ra-secondary-green {
    background-color: $ra-secondary-green !important;
    color: $ra-text-white !important;

    &.mat-expansion-panel-header {
        &:hover {
            opacity: $hover-opacity !important;
        }
    }

    .mat-expansion-indicator::after {
        color: $ra-text-white !important;
    }
}

.ra-primary-red {
    background-color: $ra-primary-red !important;
    color: $ra-text-white !important;

    &.mat-expansion-panel-header {
        &:hover {
            opacity: $hover-opacity !important;
        }
    }

    .mat-expansion-indicator::after {
        color: $ra-text-white !important;
    }
}

.mat-expansion-panel {
    border-radius: 0 !important;

    .mat-expansion-panel-header {
        .mat-content {
            display: block;
            text-align: center;
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
        padding-bottom: 1px;
    }
}

.conflicted-segment {
    color: $ra-primary-red;
}

.edited-segment {
    color: $ra-secondary-green;
}

.ra-primary-green {
    background-color: $ra-primary-green !important;
    color: $ra-text-white;

    &.mat-expansion-panel-header {
        &:hover {
            opacity: $hover-opacity !important;
        }
    }

    .mat-expansion-indicator::after {
        color: $ra-text-white !important;
    }
}

.ra-secondary-red {
    background-color: $ra-secondary-red;
    color: $ra-text-white;
}

.ra-primary-yellow {
    background-color: $warning;
    color: $ra-text-white;
}

.ra-secondary-gray {
    background-color: $border-color !important;
    color: $ra-default-text-gray !important;

    &.mat-expansion-panel-header {
        &:hover {
            opacity: $hover-opacity !important;
        }
    }
}

.ra-lightest-gray {
    background-color: $ra-lightest-gray;
    color: $black;
}

.ra-faded-blue {
    background-color: $ra-faded-blue !important;
    color: $black;
}

.faded {
    opacity: $hover-opacity;
}

.button {
    box-shadow: .12em .12em .37em $black !important;
    border-radius: unset !important;
    padding-left: .5em;
    padding-right: .5em;

    &.is-link {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-primary-blue;
            color: $ra-text-white;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-danger {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-secondary-red;
            color: $ra-text-white;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-success {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-primary-green;
            color: $black;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-primary {
        background-color: $ra-primary-blue;

        &:hover {
            opacity: $hover-opacity;
        }
    }

    i {
        margin: 0 .1em;
    }
}

::ng-deep .button {
    box-shadow: .12em .12em .37em $black !important;
    border-radius: unset !important;
    padding-left: .5em;
    padding-right: .5em;

    &.is-link {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-primary-blue;
            color: $ra-text-white;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-danger {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-secondary-red;
            color: $ra-text-white;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-success {
        background-color: $light-background;
        color: $black;

        &:hover {
            background-color: $ra-primary-green;
            color: $black;
        }

        &[disabled] {
            background-color: $light-background;
            color: $black;
        }
    }

    &.is-primary {
        background-color: $ra-primary-blue;

        &:hover {
            opacity: $hover-opacity;
        }
    }

    i {
        margin-right: .1em;
    }
}

.mat-mdc-checkbox, .mdc-checkbox {
    padding-left: 1em;

    .mdc-form-field {
        .mdc-checkbox {
            margin-right: .5em;

            .mdc-checkbox__background {
                height: 18px;
                width: 18px;
            }
        }
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
        border-color: $ra-support-gray;

    }


    &.mdc-checkbox--disabled {
        .mdc-checkbox__background {
            border-color: $border-color !important;
        }

        &.mat-mdc-checkbox-checked {
            .mdc-checkbox__background {
                background-color: $border-color !important;
            }
        }
    }

    .mdc-label {
        padding-left: 0;
    }
}


.justify-center {
    justify-content: center !important;
}

.justify-flex-end {
    justify-content: flex-end !important;
}

.justify-flex-start {
    justify-content: flex-start !important;
}

.align-center {
    align-items: center;
}

.align-flex-end {
    align-items: flex-end !important;
}

.align-baseline {
    align-items: baseline !important;
}

.bold-font {
    font-weight: 700;
}

.display-none {
    display: none;
}

.button-table-aligner {
    padding: 0 !important;

    span {
        width: 25%;
        height: 100%;

        a {
            width: 100%;
            height: 100%;

            i {
                font-size: 1.25em;
            }
        }
    }
}

.ag-row-selected {
    .ag-cell {
        background-color: $ra-faded-blue;
    }
}

.ag-theme-material {
    .ag-header-cell, .ag-header-group-cell {
        background-color: $ra-primary-blue !important;
    }
}

.dropdown-buttons {
    padding: 0;
    display: flex;
    flex-direction: column;

    app-toggle-button, .button {
        box-shadow: none !important;

        span {
            width: 100%;
            text-align: left;
        }
    }

    .button {
        justify-content: left;
    }
}

.wizard-wrapper {
    background-color: $ra-lightest-gray;

    .mat-mdc-dialog-surface {
        padding: 0 !important;
        overflow: hidden;
        position: relative;
        border: 1px solid $black;
    }
}

.mat-mdc-dialog-surface {
    padding: 1.5em !important;
}

.mdc-list-item {
    height: 3em !important;
    min-height: unset !important;

    .mdc-list-item-content {
        padding: 0 1em !important;
    }
}

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
    min-width: fit-content !important;
    width: calc(100% + 32em) !important;
    padding: 0 !important;
}


.flex-column {
    flex-direction: column;
}

.mat-mdc-menu-panel {
    min-width: 7em !important;

    .mat-mdc-menu-item {
        line-height: 3em;
        height: 3em;
        padding: 0 1em;
    }
}

.mat-mdc-tab {
    height: 3em !important;
    padding: 0 1.5em !important;
}

.mat-radio-label {
    .mat-radio-container {
        width: 1.25em;
        height: 1.25em;

        .mat-radio-outer-circle {
            border-width: 1.5px;
            height: 1.5em;
            width: 1.5em;
        }

        .mat-radio-inner-circle {
            height: 1.5em;
            width: 1.5em;
        }
    }
}

.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
        --mdc-filled-text-field-container-color: transparent;
        --mdc-filled-text-field-disabled-container-color: transparent;
        padding-right: 0;
        margin-bottom: 1.25em;

        .mat-mdc-form-field-flex {
            .mat-mdc-form-field-icon-prefix {
                align-self: baseline;

                img {
                    vertical-align: baseline;
                }

            }

            .mat-mdc-form-field-icon-suffix {
                align-self: flex-end;
            }

            .mat-mdc-form-field-infix {
                width: 11em;
                padding-bottom: 0 !important;

                .mdc-floating-label {
                    color: $black;
                    top: 26px;

                    &.mdc-floating-label--float-above {
                        transform: translateY(-150%) scale(0.75)
                    }
                }


                .mat-mdc-select {
                    padding-top: 4px;

                    .mat-mdc-select-trigger {
                        align-items: flex-end;
                    }
                }

            }
        }
    }
}

.select-overlay {
    min-width: min-content;
    width: 280px;

    .mat-mdc-checkbox {
        width: 100%;

    }
}

.mat-mdc-form-field-subscript-wrapper {
    display: none;
    //height:4px;
}

.mat-pseudo-checkbox {
    height: 18px !important;
    width: 18px !important;
    margin-right: .5em !important;
}

.mat-mdc-menu-panel {
    min-height: unset !important;
}

.overflowX-hidden {
    overflow-x: hidden;
}

.pencil-icon {
    margin-right: 1px;
    height: 2em;
    width: 2em;
}

.add-custom-daypart {
    position: absolute;
    display: block;
    top: 0;
    width: 1em !important;
    right: 10px;
    z-index: 2;
    padding: 0;
    margin: 0;
}

.create-political-window-custom-width {
    width: 100%;
}

ag-grid-angular {
    .ag-row-odd {
        background-color: $ra-lightest-gray !important;
    }
}

.overflowX-auto {
    overflow-x: auto;
}

.position-absolute {
    position: absolute;
}

.underline {
    text-decoration: underline;
}

.filter-input-align {
    .mat-mdc-select-trigger {
        bottom: 22px !important;
    }
}

input {
    font-family: $font-family;
}

.nb-theme-default {
    input {
        &[nbInput] {
            &.status-basic {
                background-color: transparent;
                border: none;
                padding: 0;
                font-weight: 500;
                font-size: 1rem;
                font-family: $font-family;
                color: $black;

                &::placeholder {
                    color: $black;
                }

                &:hover {
                    background-color: transparent;

                    &:focus {
                        background-color: transparent;
                    }
                }

                &:focus {
                    background-color: transparent;
                }
            }

        }
    }

    app-root {
        nb-layout {
            a {
                text-decoration: none;
                font-size: 1rem;
                line-height: 1.5;
                color: $ra-primary-blue;

                &:hover {
                    color: $ra-text-white;
                }

            }

            font-size: 1rem;
            line-height: 1.5;

            button {
                line-height: normal;
            }

            .layout {
                .layout-container {
                    .content {
                        .columns {
                            margin: 0;

                            nb-layout-column {
                                height: 100vh;
                                padding: 0;
                                font-size: 1rem;
                                line-height: 1.5;

                                font-family: $font-family;
                                color: $black;
                            }
                        }
                    }
                }
            }

            .nb-timepicker-container {
                .apply-text-button {
                    &.status-primary {

                        background-color: $ra-primary-blue;
                        border-color: $ra-primary-blue;
                        line-height: normal;
                    }
                }

                .header-cell {
                    color: $black;
                }

                .values-list {
                    overflow: auto;
                    overflow-x: hidden;
                    scroll-snap-type: y;

                    .list-item {
                        color: $ra-primary-blue;
                        margin-right: 0 !important;

                        &.selected {
                            background-color: rgba($ra-primary-green, .4);
                            color: $ra-primary-blue;

                        }

                        &:hover {
                            background-color: rgba($ra-primary-green, .4);
                            color: $ra-primary-blue;

                        }
                    }
                }


            }
        }


    }


}

.overflow-auto {
    overflow: auto;
}
