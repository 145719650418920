$black: #05053A;

$border-color: #bbb;
$faded-border-color: #ccc;
$light-background: #fff;
$darker-background: #fafafa;
$darkest-background: #f0f0f0;

$ra-primary-blue: #101D91;
$ra-faded-blue: rgba($ra-primary-blue, .2);
$ra-semi-faded-blue: rgba($ra-primary-blue, .5);
$ra-support-gray: rgba($black, .75);
$ra-lightest-gray: rgba($black, .1);
$ra-default-text-gray: $ra-support-gray;
$ra-text-white: #fff;
$ra-light-blue: rgb(169, 195, 213);

$ra-primary-red: #B70F0A;
$bright-red: #ff0000;
$ra-primary-green: #72E2AF;
$ra-secondary-green: #629C44;
$ra-secondary-red: $ra-primary-red;
$ra-primary-orange: #ff6f2c;
$ra-light-orange: #f3af88;
$ra-light-green: #8fe0bc;
$ra-highlight-green: #c7f3df;
$ra-light-purple: #9c90c2;
$ra-light-cyan: #b9ffff;


$hover-opacity: .5;

// bulma overrides
$link: $ra-primary-blue;
$primary: $ra-primary-blue;
$success: $ra-primary-green;
$danger: $ra-secondary-red;
$warning: #ffdd57;
$info: $ra-primary-blue;
$text: $black;
$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: .75rem;

//dynamic ratecard override colors
$overridden-grey: $ra-support-gray;
$lower-override-red: $ra-secondary-red;
$higher-override-green: $ra-secondary-green;

//sellout colors
$sellout-lower-limit-background: #DDFFDD;
$sellout-upper-limit-background: #FEE8E9;

$ra-dont-edit-grey: rgba($black, .8);
$ra-dont-edit-political-grey: rgba($black, .38);

$default-nav-height: 2.5rem;

$mobile-nav-height: 5.5rem;

$font-family: "Sofia Pro", "Century Gothic", Futura;
