/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '@material/density' as mdc-density;
@use '@material/textfield';
@use "@material/button";

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
.mat-mdc-floating-label {
    display: inline !important;
}

@import "palette";
@import "variables";
@import "bulma/bulma";
@import "custom";
@import "ra-card";
@import "ag-grid-community/src/styles/ag-grid";
@import "ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
@import "ag-grid-custom";
@import '@angular/material/theming';


//@import "@angular/material/legacy-prebuilt-themes/legacy-indigo-pink.css";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
    $font-family: $font-family,
    $headline: mat-typography-level(2rem, 3rem, 700),
    $body-1: mat-typography-level(1rem, 1.5rem, 500),
    $display-4: mat-typography-level(8rem, 8rem, 300),
    $display-3: mat-typography-level(4rem, 4rem, 400),
    $display-2: mat-typography-level(3rem, 3rem, 400),
    $display-1: mat-typography-level(2.5rem, 3rem, 400),
    $title: mat-typography-level(1.5rem, 2rem, 500),
    $subheading-2: mat-typography-level(1rem, 2rem, 500),
    $subheading-1: mat-typography-level(1rem, 1.5rem, 500),
    $body-2: mat-typography-level(1rem, 1.5rem, 500),
    $caption: mat-typography-level(.75rem, 1.5rem, 500),
    $button: mat-typography-level(1rem, 1rem, 500),
    $input: mat-typography-level(1rem, 1.5, 500)
);

$foreground: (
    base: $ra-primary-blue,
    divider: $black,
    dividers: $black,
    disabled: rgba($black, .4),
    disabled-button: rgba($black, .4),
    disabled-text: rgba($black, .4),
    hint-text: $black,
    secondary-text: $black,
    icon: $black,
    icons: $black,
    text: $black,
    slider-min: $ra-primary-blue,
    slider-off: $ra-primary-blue,
    slider-off-active: $ra-primary-blue,
);

$background: (
    status-bar: map_get($mat-primary, 300),
    app-bar: map_get($mat-primary, 100),
    background: map_get($mat-primary, 50),
    hover: rgba($ra-primary-green, .4),
    card: $ra-text-white,
    dialog: $ra-text-white,
    disabled-button: rgba($black, .4),
    raised-button: $ra-text-white,
    focused-button: rgba($black, .05),
    selected-button: map_get($mat-primary, 300),
    selected-disabled-button: map_get($mat-primary, 400),
    disabled-button-toggle: map_get($mat-primary, 200),
    unselected-chip: map_get($mat-primary, 300),
);

$theme: (
    color: (
        primary: mat.define-palette($mat-primary),
        tertiary: mat.define-palette($mat-primary),
        accent: mat.define-palette($mat-primary),
        warn: mat.define-palette($mat-primary),
        foreground: $foreground,
        background: $background
    ),
    typography: $custom-typography,
    density: -3
);


$mat-theme-ignore-duplication-warnings: true;

@include angular-material-typography($custom-typography);
@include mat-core();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated */
@include mat.core(); // this is the important line
//@include mat-radio-typography($custom-typography);
@include angular-material-theme($theme);
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated */
@include mat.all-component-themes($theme); // this is the important line

html {
    overflow-y: auto;
}

html, body {
    height: 100%;
    font-size: 10.72px;
    font-family: $font-family !important;
    @include mat.core-density(-5);
    @include mat.option-density(-5);
    @include mat.optgroup-density(-5);
    @include mat.autocomplete-density(-5);
    @include mat.badge-density(-5);
    @include mat.bottom-sheet-density(-5);
    @include mat.fab-density(-5);
    @include mat.icon-button-density(-5);
    @include mat.button-toggle-density(-5);
    @include mat.card-density(-5);
    @include mat.checkbox-density(-5);
    @include mat.chips-density(-5);
    @include mat.datepicker-density(-5);
    @include mat.dialog-density(-5);
    @include mat.divider-density(-5);
    @include mat.expansion-density(-5);
    @include mat.form-field-density(-5);
    @include mat.grid-list-density(-5);
    @include mat.icon-density(-5);
    @include mat.input-density(-5);
    @include mat.list-density(-5);
    @include mat.menu-density(-5);
    @include mat.paginator-density(-5);
    @include mat.progress-bar-density(-5);
    @include mat.progress-spinner-density(-5);
    @include mat.radio-density(-5);
    @include mat.select-density(-5);
    @include mat.sidenav-density(-5);
    @include mat.slide-toggle-density(-5);
    @include mat.slider-density(-5);
    @include mat.snack-bar-density(-5);
    @include mat.sort-density(-5);
    @include mat.stepper-density(-5);
    @include mat.table-density(-5);
    @include mat.tabs-density(-5);
    @include mat.toolbar-density(-5);
    @include mat.tooltip-density(-5);
    @include mat.tree-density(-5);
}

.ag-theme-material {
    @include ag-theme-material((
        cell-horizontal-padding: 0,
        row-height: 24px,
        header-height: 20px,
        font-size: .8rem,
        font-family: ("Sofia Pro", "Century Gothic", Futura)
    ));
}

.station-exceptions-icon {
    font-size: 12px;
    color: white;
    cursor: pointer;
    padding-right: 2em;
    padding-top: 0.4em;
}

